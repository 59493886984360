.page-app { display: flex; flex-direction: column; min-height: 0; }

.app-inner { flex: 1 1 auto; min-height: 0; height: 100%; }
.app-main { flex: 1 1 auto; min-width: 0; display: flex; height: 100%; }
.app-pane { flex: 1 1 auto; min-width: 0; position: relative; height: 100%; min-height: 0; }


@media screen and (max-width: 767px) {
    .app-main { flex-direction: column; }
}
