.product-menu { width: 206px; flex: 0 0 auto; background-color: #f5f5f5; height: 100%; display: flex; flex-direction: column; }

.product-menu .product-menu-main { flex: 1 1 auto; min-height: 0; overflow: auto; overflow-scrolling: touch; }
.product-menu .product-menu-main::-webkit-scrollbar { width: 10px; }
.product-menu .product-menu-main::-webkit-scrollbar-track { background: #d7d7d7; }
.product-menu .product-menu-main::-webkit-scrollbar-thumb { background: #A8A8A8; }
.product-menu .product-menu-main::-webkit-scrollbar-thumb:hover { background: #555; }

.product-menu .product-menu-foot { flex: 0 0 auto; }
.product-menu .product-menu-foot .btn { border-radius: 0; }

.product-menu .product-menu-item { position: relative; text-align: center; height: 210px; display: flex; align-items: center; justify-content: center; flex-direction: column; cursor: pointer; }
.product-menu .product-menu-item + .product-menu-item { border-top: 2px solid #ececec; }
.product-menu .product-menu-item .item-click-overlay { position: absolute; top: 0; right: 0; bottom: 0; left: 0; z-index: 2; }
.product-menu .product-menu-item .item-image { margin-bottom: 15px;  }
.product-menu .product-menu-item .item-image img { max-width: 120px; max-height: 90px; width: auto; height: auto; }
.product-menu .product-menu-item .item-main { font-size: 16px; font-weight: 500; color: #333; margin-bottom: 12px; transition: 150ms; }
.product-menu .product-menu-item .item-main .item-name { margin-bottom: 6px; }
.product-menu .product-menu-item .item-main .item-price { color: #666; }
.product-menu .product-menu-item .item-variants { display: flex; justify-content: center; transition: 150ms; }
.product-menu .product-menu-item .item-variants .variant { margin: 0 4px; width: 30px; height: 20px; border-radius: 3px; overflow: hidden; position: relative; z-index: 3; }
.product-menu .product-menu-item .item-variants .variant.current { border: #000 2px solid; }
.product-menu .product-menu-item .item-variants .variant img { width: 100%; height: 100%; }
.product-menu .product-menu-item.current { background-color: #ececec; }

.product-menu .product-menu-main.mobile { display: none; }

@media screen and (max-width: 767px) {
    .product-menu { flex-direction: column; height: 200px; width: 100%; }

    .product-menu .product-menu-item { height: 100%; width: 180px; margin: 0 auto; }
    .product-menu .product-menu-item .item-image { margin-bottom: 5px; }
    .product-menu .product-menu-item .item-image img { max-height: 70px; }
    .product-menu .product-menu-item .item-main { display: flex; }
    .product-menu .product-menu-item .item-main .item-name { margin-bottom: 0; margin-right: 10px; }

    .product-menu .product-menu-main { height: 100%; }
    .product-menu .product-menu-main.mobile { display: block; }
    .product-menu .product-menu-main.desktop { display: none; }
    .product-menu .product-menu-main .slick-slider,
    .product-menu .product-menu-main .slick-list,
    .product-menu .product-menu-main .slick-track,
    .product-menu .product-menu-main .slick-slide > div { height: 100%; }
    .product-menu .product-menu-main .slick-slide:not(.slick-current) { opacity: .5; }
    .product-menu .product-menu-main .slick-slide:not(.slick-current) .item-main { opacity: 0; }
    .product-menu .product-menu-main .slick-slide:not(.slick-current) .item-variants { opacity: 0; }
}
