.header { flex: 0 0 auto; background-color: #fff; display: flex; justify-content: space-between; align-items: center; height: 50px; padding: 0 20px; }
.header .logo { display: block; color: #333; }
.header .logo h1 { display: flex; align-items: center; }
.header .logo h1 .icon { line-height: 0; }
.header .logo h1 .icon + .icon { margin-left: 20px; }

.header .Dropdown-root { position: relative; }

.header .Dropdown-control { font-weight: 500; font-size: 16px; position: relative; overflow: hidden; color: #333; text-transform:lowercase; border: 2px solid #e3e3e3; cursor: default; outline: none; padding: 5px 30px 6px 14px; border-radius: 5px; transition: all 200ms ease; right: 0; min-width: 168px; }


.header .Dropdown-arrow {border-color: #333 transparent transparent; border-style: solid; border-width: 5px 5px 0; content: ' '; display: block; height: 0; position: absolute; right: 10px; top: 50%; margin-top: -2px; width: 0; }


.header .Dropdown-menu { right: 0; background-color: white; border: 1px solid #ccc; box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); box-sizing: border-box; margin-top: -1px; max-height: 200px; overflow-y: auto; position: absolute; top: 100%; width: 100%; z-index: 10000; -webkit-overflow-scrolling: touch; }

.header .Dropdown-menu .Dropdown-group > .Dropdown-title { padding: 8px 10px; z-index: 10000; color: rgba(51, 51, 51, 1.2); font-weight: bold; text-transform: capitalize; }

.header .Dropdown-placeholder {font-size: 1.25em; }
.header .Dropdown-noresults { box-sizing: border-box; color: #ccc; cursor: default; display: block; padding: 8px 10px; }

.header .Dropdown-option { box-sizing: border-box; z-index: 10000; color: rgba(20, 20, 20, 1); font-weight:400; cursor: pointer; display: block; padding: 8px 10px; text-transform: lowercase; }
.header .Dropdown-option:last-child { border-bottom-right-radius: 2px; border-bottom-left-radius: 2px; }
.header .Dropdown-option:hover { background-color: #f2f9fc; color: #333; }
.header .Dropdown-option.is-selected { background-color: #f2f9fc; color: #333; }

.header .Dropdown-root.is-open .Dropdown-arrow { border-color: transparent transparent #666; border-width: 0 5px 5px; }
