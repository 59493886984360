*{box-sizing: border-box; padding: 0; margin: 0;}
html, body{width: 100%; height: 100%; padding: 0; margin: 0; overflow-x: hidden;}

.overlay {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #eeeeee;
  text-align: center;
  position: fixed;
  z-index: 10000;
  text-align: center;
}


.overlay .header h1, .tos h2, .tos p {
  margin-top:2em;
  text-transform:lowercase;

}

.overlay .logo {
  margin-top:1.5em;
  font-weight:100;

  margin-right:1em;
}

.overlay .logo h1 {
  font-size: 5em;
  font-weight:100;

}

.header h1 {
  font-weight:100;

}

.overlay .initCamera {

  text-transform:lowercase;
  margin-top:2em;
  padding:.5em;
  background: none;
  box-shadow: none;
  border: 1px #000 solid;
  font-size: 2em;
  font-weight:300;
  width: 150px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out; 
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.overlay .initCamera:hover{background: #f2f9fc; color: #000;}

.productMenu{width: 100%; overflow-x: auto;}
.productMenu .productMenuItem{
  animation: 3s ease-out 0s 1 fadeIn;
  cursor: pointer;
 }

 @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.productVariants{padding: 30px 0; margin: 0;}

.productVariants img{
  -webkit-transition: all 0.3s ease-in-out; 
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  animation: 4s ease-out 0s 1 fadeIn;
 }
 