.page-home { overflow: hidden; position: relative; display: flex; align-items: center; justify-content: center; }

.home-background { position: absolute; top: 0; left: 0; z-index: 1; width: 100vw; height: 100vh; }

.home-background .hex { position: absolute; top: 50%; left: 50%; }
.home-background .hex .hex-inner { position: relative; margin: 0 auto; border-radius: 70px; height: 870px; width: 560px; transform: rotate(30deg); }
.home-background .hex .hex-inner:before,
.home-background .hex .hex-inner:after { content: ''; position: absolute; top: 0; left: 0; background-color: inherit; border-radius: inherit; height: 100%; width: 100%; }
.home-background .hex .hex-inner:before { transform: rotate(60deg); }
.home-background .hex .hex-inner:after { transform: rotate(-60deg); }

.home-background .hex:nth-child(1) { transform: translate(-50%, -50%); }
.home-background .hex:nth-child(1) .hex-inner { background-color: #f8ce8d; }

.home-background .hex:nth-child(2) { transform: translate(-131%, -140%); }
.home-background .hex:nth-child(2) .hex-inner { background-color: #d7e9de; }

.home-background .hex:nth-child(3) { transform: translate(31%, -140%); }
.home-background .hex:nth-child(3) .hex-inner { background-color: #c6bbd4; }

.home-background .hex:nth-child(4) { transform: translate(112%, -50%); }
.home-background .hex:nth-child(4) .hex-inner { background-color: #d7e9de; }

.home-background .hex:nth-child(5) { transform: translate(31%, 40%); }
.home-background .hex:nth-child(5) .hex-inner { background-color: #eeccc1; }

.home-background .hex:nth-child(6) { transform: translate(-131%, 40%); }
.home-background .hex:nth-child(6) .hex-inner { background-color: #c6bbd4; }

.home-background .hex:nth-child(7) { transform: translate(-212%, -50%); }
.home-background .hex:nth-child(7) .hex-inner { background-color: #eeccc1; }

.home-content { position: relative; z-index: 2; width: 660px; font-weight: 600; font-size: 36px; line-height: 1.17; color: #575757; text-align: center; }
.home-content .logo { margin: 0 auto 35px; background-image: url(../../assets/logo.png); background-size: 100% 100%; width: 148px; height: 233px; }
.home-content p { margin-bottom: 35px; }
.home-content .btn { margin: 10px; }


@media screen and (max-width: 767px) {
    .home-background .hex .hex-inner { border-radius: 35px; height: 435px; width: 280px; }

    .home-content { width: 100%; padding: 0 20px; font-size: 20px; }
    .home-content .logo { width: 74px; height: 116.5px; margin-bottom: 20px; }
    .home-content p { margin-bottom: 20px; }
}
